@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: visible;
}
body {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  min-height: 100vh;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  padding: 0px;
}

h1,
h2 {
  font-family: 'Montserrat', sans-serif;
}

// ul,
// li {
//   list-style-type: none;
//   margin: 0px;
//   padding: 0px;
// }

a {
  text-decoration: none;
}

.btn,
button,
button:focus,
input:focus,
// textarea:focus,
select,
select:focus,
a:focus {
  outline: 0px !important;
}

button {
  cursor: pointer;
}

button[disabled] {
  pointer-events: none;
}

input {
  padding: 0;
}

input:required {
  -webkit-box-shadow: none;
  box-shadow: none;
}

input:invalid {
  -webkit-box-shadow: none;
  box-shadow: none;
}

::-webkit-scrollbar {
  height: 6px;
  width: 8px;
  transition:
    width 0.3s,
    height 0.3s;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  width: 0px;
  border-radius: 10px;
  background-color: transparent;
  transition: background-color 0.3s;
}
::-webkit-scrollbar-track-piece {
  background-color: transparent;
  border-radius: 6px;
}
// :hover::webkit-scrollbar-track{
// 	background-color: #ebe9e9;
// }
// :hover::-webkit-scrollbar-track-piece {
// 	background-color: #ebe9e9;
// 	border-radius: 6px;
//   }
:hover::-webkit-scrollbar-thumb {
  background-color: #8c909b;
}

// /* hover 狀態下的 scrollbar */
// :hover::-webkit-scrollbar {
//   width: 8px;
//   height: 6px;
// }

// :hover::-webkit-scrollbar-thumb {
//   width: 6px;
//   background-color: #8C909B;
// }

// 文字垂直排列
.mixed {
  writing-mode: vertical-lr;
  text-orientation: mixed;
}
.upright {
  writing-mode: vertical-lr;
  text-orientation: upright;
}

// hidden input number 預設樣式
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// hidden input number 預設樣式
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// phone number input
.PhoneInputInput {
  height: 56px;
  padding: 16px 8px 16px 72px;
  border: 2px solid #d9dade;
  border-radius: 8px;
}
.PhoneInputInput:focus {
  border: 2px solid #7a9bff;
}

.PhoneInputCountryIcon {
  width: 24px !important;
  height: 24px !important;
}

.PhoneInputCountryIcon--border {
  /* Removed `background-color` because when an `<img/>` was still loading
	   it would show a dark gray rectangle. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom. */
  background-color: transparent !important;
  /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
  /* For some reason the `<img/>` is not stretched to 100% width and height
	   and sometime there can be seen white pixels of the background at top and bottom,
	   so an additional "inset" border is added. */
  box-shadow: none !important;
}

.PhoneInputCountryIconImg {
  /* Fixes weird vertical space above the flag icon. */
  /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
  display: block;
  /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
	   Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
  width: 100%;
  height: 100%;
}

.PhoneInputCountry {
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  align-self: stretch;
  display: flex;
  gap: 6px;
  align-items: center;
  background-color: none !important;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: transparent !important;
}

// nav menu icon transition
$menu-icon-line-height: 2px;
$menu-icon-line-space: 4px;
$menu-icon-line-offsetY: $menu-icon-line-height + $menu-icon-line-space;
$menu-icon-height: (3 * $menu-icon-line-height) + (2 * $menu-icon-line-space);
$cubic-bezier-in: cubic-bezier(0.3, 1, 0.7, 1);
$cubic-bezier-out: cubic-bezier(1, 0.7, 1, 0.3);

.menu-icon {
  width: 20px;
  height: 15px;
  cursor: pointer;

  .line-1,
  .line-2,
  .line-3 {
    width: 100%;
    height: $menu-icon-line-height;
    background: #262d42;
    border-radius: 5px;
    // box-shadow: 0 2px 10px 0 rgba(0,0,0,0.3);
    // transition: background .2s ease-in-out
  }
  .line-1 {
    animation: animate-line-1-rev 0.7s ease-in-out;
  }
  .line-2 {
    margin: $menu-icon-line-space 0;
    animation: animate-line-2-rev 0.7s ease-in-out;
  }
  .line-3 {
    animation: animate-line-3-rev 0.7s ease-in-out;
  }
  &:hover {
    .line-1,
    .line-2,
    .line-3 {
      background-color: #262d42;
    }
  }
  &.active {
    .line-1,
    .line-2,
    line-3 {
      background-color: #262d42;
    }
    .line-1 {
      animation: animate-line-1 0.7s $cubic-bezier-in forwards;
    }
    .line-2 {
      animation: animate-line-2 0.7s $cubic-bezier-in forwards;
    }
    .line-3 {
      animation: animate-line-3 0.7s $cubic-bezier-in forwards;
    }
  }
}

.no-animation {
  -webkit-animation: none !important;
  animation: none !important;
}

@keyframes animate-line-1 {
  0% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  50% {
    transform: translate3d(0, $menu-icon-line-offsetY, 0) rotate(0);
  }
  100% {
    transform: translate3d(0, $menu-icon-line-offsetY, 0) rotate(45deg);
  }
}
@keyframes animate-line-2 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
@keyframes animate-line-3 {
  0% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  50% {
    transform: translate3d(0, -$menu-icon-line-offsetY, 0) rotate(0);
  }
  100% {
    transform: translate3d(0, -$menu-icon-line-offsetY, 0) rotate(135deg);
  }
}

@keyframes animate-line-1-rev {
  0% {
    transform: translate3d(0, $menu-icon-line-offsetY, 0) rotate(45deg);
  }
  50% {
    transform: translate3d(0, $menu-icon-line-offsetY, 0) rotate(0);
  }

  100% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
}

@keyframes animate-line-2-rev {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes animate-line-3-rev {
  0% {
    transform: translate3d(0, -$menu-icon-line-offsetY, 0) rotate(135deg);
  }
  50% {
    transform: translate3d(0, -$menu-icon-line-offsetY, 0) rotate(0);
  }
  100% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
}

.react-datepicker {
  padding: 8px 6px !important;
  border: unset !important;
  border-radius: 14px !important;
  box-shadow: 1px 1px 6px 0px rgba(0, 8, 33, 0.15) !important;

  .react-datepicker__navigation--previous {
    top: 26px !important;
    left: unset !important;
    right: 44px !important;
    width: 16px !important;
    height: 16px !important;
  }
  .react-datepicker__navigation--next {
    top: 26px !important;
    right: 15px !important;
    width: 16px !important;
    height: 16px !important;
  }

  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: #fff !important;
      text-align: start !important;
      border: unset !important;
    }
  }
}

.react-datepicker__month {
  margin: 0 0 0 0 !important;
}

.react-datepicker__current-month {
  display: none !important;
  padding: 6px 10px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #262d42 !important;
}

.react-datepicker__day-name {
  width: 40px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #595f6f !important;
  margin: unset !important;
}

.react-datepicker__day {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #595f6f !important;
  margin: unset !important;
}

.react-datepicker__day:hover {
  color: #262d42 !important;
  box-shadow: 999px 999px 0px 0px var(rgba(0, 8, 33, 0.04)) inset !important;
}

.react-datepicker__day--selected {
  display: none;
  background-color: #cfecff !important;
  color: #262d42 !important;
}
.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #000821 !important;
  border-width: 2px 2px 0 0 !important;
}

.custom-day-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.custom-day-contents.today {
  background-color: #0029a5;
  border-radius: 8px;
  color: #fcfcfc;
}

.event-count {
  font-size: 0.8em;
  color: #888;
}

.react-datepicker__day {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px !important;
  width: 40px !important;
}

.react-datepicker__day--selected .event-count {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: black;
}

.event-dots {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2px;
  height: 6px;
  padding: 0px 11px;
}

.event-dot {
  width: 2px;
  height: 2px;
  border-radius: 100%;
  background-color: #0029a5;
}

.today-dots .event-dot {
  background-color: white;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__week {
  display: flex;
  justify-content: flex-start;
}

.react-datepicker__day {
  flex: 0 0 40px; /* 7天等寬 */
  max-width: 40px;
}

.react-datepicker__year-dropdown-container {
  margin: 0 !important;
}

.react-datepicker__header__dropdown {
  padding: 8px 8px !important;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
  color: #262d42;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}

.custom-year-dropdown {
  padding: 2px 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
}

.blog-content {
  // 恢復默認樣式
  overflow: hidden;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    font-weight: 600;
    line-height: 1.3;
  }

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.75em;
  }
  h3 {
    font-size: 1.5em;
  }
  h4 {
    font-size: 1.25em;
  }
  h5 {
    font-size: 1.1em;
  }
  h6 {
    font-size: 1em;
  }

  p {
    margin-bottom: 1em;
    line-height: 1.7;
  }

  // 保留您的自定義樣式
  a {
    color: #0029a5;
  }

  ul {
    list-style: disc;
    padding-left: 2em;
  }

  ol {
    list-style: decimal;
    padding-left: 2em;
  }

  li {
    margin-bottom: 0.5em;
  }

  // 添加其他元素的默認樣式
  blockquote {
    margin: 1em 0;
    padding-left: 1em;
    border-left: 4px solid #ccc;
  }

  pre,
  code {
    background-color: #f4f4f4;
    padding: 0.2em 0.4em;
    border-radius: 3px;
  }

  img {
    max-width: 100%;
    height: auto;
    //   margin: 0 auto;
  }

  figure.image.image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
    img {
      margin-left: 0;
      margin-right: auto;
    }
    figcaption {
      text-align: center;
      font-size: 12px;
    }
  }

  figure.image.image-style-block-align-right {
    margin-left: auto;
    margin-right: 0;
    img {
      margin-right: 0;
      margin-left: auto;
    }
    figcaption {
      text-align: center;
      font-size: 12px;
    }
  }

  figure.image {
    position: relative;
    margin: 0.9rem auto;
    img {
      margin: 0 auto;
    }
    figcaption {
      text-align: center;
      font-size: 12px;
    }
  }

  figure .image .image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
  }

  figure.table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 1em;
  }

  th,
  td {
    border: 1px solid #000;
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #f2f2f2;
  }
  mark {
    background: yellow;
  }
  .marker-green {
    background: green;
  }
  .marker-red {
    background: red;
  }
  .marker-blue {
    background: blue;
  }
  .marker-orange {
    background: orange;
  }
  .marker-pink {
    background: pink;
  }

  @media (max-width: 767px) {
    h1 {
      font-size: 1.5em;
    }
    h2 {
      font-size: 1.3em;
    }
    h3 {
      font-size: 1.1em;
    }
    h4,
    h5,
    h6 {
      font-size: 1em;
    }

    p,
    ul,
    ol {
      font-size: 0.9em;
    }

    img {
      width: 100%;
      height: auto;
    }

    table {
      font-size: 0.8em;
    }

    figure.table {
      overflow-x: auto;
      white-space: nowrap;
    }

    blockquote {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0.5em;
    }
  }
}

// 手機版媒體查詢
@media (max-width: 767px) {
  .blog-content iframe,
  .blog-content .media {
    margin-left: -1em;
    margin-right: -1em;
    // width: calc(100% + 2em);
  }
}

.blog-content iframe {
  max-width: 100%;
  width: 100%;
  aspect-ratio: 16 / 9;
}

.blog-content .media {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 比例 */
  height: 0;
  overflow: hidden;
}

.blog-content .media iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@keyframes move-cards {
  from {
    transform: translateY(100px);
  }
  to {
    transform: translateY(-100px);
  }
}

// 為每個欄位添加不同的延遲
.view-timeline-column {
  view-timeline-name: --cards;
  view-timeline-axis: block;
  animation-fill-mode: both; // 保持動畫最後的狀態
  animation-timing-function: linear;

  &:nth-child(1) {
    animation-delay: 0s;
  }

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
}

.rainbow-glow {
  position: absolute;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 0%);
  background: transparent;
  overflow: visible;
  z-index: -1;
  @media (min-width: 768px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}

.rainbow-glow::before {
  content: '';
  position: absolute;
  inset: 0;
  background: conic-gradient(
    from 180deg at 50% 50%,
    #ff6161 0deg,
    #ffd361 70.5deg,
    #95ffa0 162.37deg,
    #95b9ff 233.62deg,
    #d795ff 310.5deg,
    #ff6161 360deg
  );
  filter: blur(50px);
  opacity: 0.7;
  mix-blend-mode: screen;
  border-radius: 100%;
  animation: glow 15s linear infinite;
  @media (min-width: 768px) {
    filter: blur(100px);
  }
}
// 外圍模糊效果
.rainbow-glow::after {
  content: '';
  position: absolute;
  inset: -50px; // 擴大範圍
  background: inherit; // 繼承相同的漸層
  filter: blur(100px); // 更大的模糊半徑
  opacity: 0.4; // 較低的不透明度
  mix-blend-mode: screen;
  @media (min-width: 768px) {
    inset: 0px;
    filter: blur(50px);
  }
}

@keyframes glow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
